import React from 'react';

export const Edinburgh = {
    "name": "Edinburgh",
    "link": "edinburgh",
    "country": "Scotland",
    "continent": "Europe",
    "thumb": "https://live.staticflickr.com/65535/50848656606_89ec644249_t.jpg",
    "image": "https://live.staticflickr.com/65535/50848656606_ff6561d0ef_o.jpg",
    "caption": "The city hotbed of literature genius",

    "description": "With a famous medieval Old Town, neoclassical buildings and a jaw-dropping architecture, Scotland's Capital brings together several museums, important monuments and Whiskey, of course! In addition to all these attractions, the city is also the birthplace of some of the most famous writers in the world. Marked by literary culture, and especially by Scottish culture, this is for us one of the most captivating places to visit!",

    "nrDays":<p>
        Even though it is the capital city of Scotland, Edinburgh city center is quite small.
        However, there are a lot of attractions to visit here. From museums, iconic places and the famous Edinburgh Castle, you will have a lot to see.
        As such, we recommend at least <b>3 full days</b> to check all the main spots. If you're short in time or you're looking to visit other parts of Scotland you can try to visit the most important things on the city in two days.
    </p>,

    "whenVisit": <p>
        The best time to visit Edinburgh, as well as the rest of Scotland, is when temperatures start to increase a little
        and large numbers of tourists have not yet arrived. For this, we recommend, the months before and after the high
        season, that is, <b>May</b> and <b>early June</b>, and <b>September</b> and <b>early October</b>.
        <br/><br/>
        If you don’t have problems with large crowds of tourists, and of course, high prices in accommodation and tours, visit during the month of August and early
        September, when the <b>Edinburgh Festival</b> takes place. At this time the city is extremely lively, with lots of artists on the streets
        and many performances in art houses, theaters, operas and museums.
    </p>,

    "mobility": <p>
        The historic centre of Edinburgh (more precisely the Old Town), is where almost all points of interest are, and
        it is possible to visit all the main attractions <b>on foot</b>.
        <br/><br/>
        However, some hotels and Airbnbs may be a little further away from the center, in which case the best option is to <b>use the bus</b> to go to the centre.
        Each trip costs around £1.70, and if you use a contactless card, you have unlimited daily travel capped at £4.
        The bus accepts coins too, but bring in the right amount, since they don't give change.
        <br/><br/>
        The trams connect Edinburgh Airport to York Place, with stops at Princes Street and Haymarket Train Station, which
        can be a good supplementary option to the bus or taxi to make the journey.
    </p>,

    "safety": <p>
        Edinburgh is a particularly <b>safe city</b> for tourists. It is relatively harmless to walk at night but be sure to collect
        information from the areas you are going to. Some areas of the city, especially the Old Town, are filled of winding
        alleys, closures and paths, making it quite easy to get lost at night.
        </p>,

    "itinerary": {
        "description": "Based on our experience while visiting the city, here is a suggestion of a 3-day itinerary around the city. Following this you won't have to take extremely long walks and will have time to visit everything calmly.",
        "days": [
            {
                "description": <p>
                   For the first day, as in all other major European cities, we recommend you start your visit with
                   a <b>free walking tour</b> around the city. It is an excellent way to learn about the city's history and legends, as well
                   as having a presentation of the most iconic places of Scottish culture.
                   <br/><br/>
                   After a quick lunch, you can enjoy the early afternoon by visiting the famous <b>Edinburgh's castle</b>,
                   Scotland's most visited attraction. Following <b>Castlehill</b>, this street that leads to the castle is full of shops and Scots playing the bagpipes.
                   <br/><br/>
                   Next up is one of the main stops for fans of the Harry Potter saga (you'll find a lot in this city), <b>Victoria Street</b>,
                   the most photographed street in Scotland. To end the afternoon, we recommend visiting <b>Grassmarket Square</b> and <b>Princes Street Gardens</b>.
                   On a cloudless day, it is also a good opportunity to walk to the North Bridge, above the station, to watch the beautiful sunset.
                </p>,
                "image": "https://live.staticflickr.com/65535/50847932878_f22bd27aea_o.jpg",
                "caption": "Edinburgh Castle in a sunny day."
            },
            {
                "description": <p>
                    If you start the second day early, nothing better than watching the wonderful view of the city on <b>Calton Hill</b>.
                    Near this area you will find the <b>National Monument of Scotland</b>, an unfinished acropolis.
                    <br/><br/>
                    If you visit the city in late April, don't miss the <b>Beltane Fire Festival</b> procession.
                    With dancing and fire and lots of artists, it will be a remarkable memory.
                    <br/><br/>
                    Then continue the trek to the <b>Scottish Parliament</b>, in front of which is the famous <b>Palace of Holyrood</b>.
                    To end the afternoon, nothing better than walking to the top of <b>Arthur’s Seat</b> to enjoy an amazing sunset.
                    For dinner we recommend that you try the famous <b>haggis</b>, while not very attractive at first, it is an important gastronomical experience.
                    To end this fantastic day, nothing better than having fun at a <b>traditional pub</b> in the city.
                </p>,
                "image": "https://live.staticflickr.com/65535/50848656891_f699853a79_o.jpg",
                "caption": "View of the city from Arthur's Seat."
            },
            {
                "description": <p>
                    For the third day we recommend a visit to the <b>National Museum of Scotland</b> (free admission). Close by, you should
                    stop at <b>Greyfriars Cemetery</b> and visit the statue of the famous <b>puppy Bobby</b> (you will probably hear about him
                    on the walking tour, otherwise it will be a failure). Next, visit another famous J.K. Rowling site, the <b>Elephant
                    House</b>. This is a place that easily fills up and if you don't want to wait in line, we recommend that you try
                    to lunch at The Outsider, it's very close.
                    <br/><br/>
                    Following the road, you will find <b>St Giles Cathedral</b>, the last main spot to visit in the city.
                </p>,
                "image": "https://live.staticflickr.com/65535/50848744787_4300a05994_o.jpg",
                "caption": "Elephant House."
            }
        ]
    },

    "highlights": [
        {
            "name": "Edinburgh Castle",
            "description": <p>
                Edinburgh Castle is the <b>most visited attraction</b> in all of Scotland. It sits on top of a hill and can be seen from
                almost anywhere in the city. As a result, the queues for ticket lines are long, so we suggest you <b>buy</b> it <b>in advance</b>. If you just
                want to see the entrance with a view of the city and the bleachers in the courtyard, a ticket is not necessary, but we absolutely recommend a visit inside.
            </p>,
            "image": "https://live.staticflickr.com/65535/50847932803_ed6fbd648d_o.jpg"
        },
        {
            "name": "Victoria Street",
            "description": <p>
                Once down Castle Hill you will find the famous Victoria Street. As mentioned above,
                this is the <b>most photographed street</b> in all of Scotland. With several colorful buildings
                and lots of shops with Harry Potter items, this street is famous for being <b>Diagon Alley's</b> supposed inspiration.
            </p>,
            "image": "https://live.staticflickr.com/65535/50847932788_84ac9acb04_o.jpg"
        },
        {
            "name": "Calton Hill",
            "description": <p>
                Calton Hill is one of the most charming places in the city. With some historical monuments
                located here, some of which are considered <b>World Heritage Sites</b> by UNESCO. This hill is also marked by
                a rich history, a place where medieval events and festivals were held, as well as public executions.
            </p>,
            "image": "https://live.staticflickr.com/65535/50848744747_26a2da3322_o.jpg"
        },
        {
            "name": "Arthur’s Seat",
            "description": <p>This mountain, although no longer having the characteristic shape of a cone, was an ancient volcano.
                Being the highest point in the city it is the perfect place to watch the sunrise or sunset. This place also has a vast
                green area around it, with small lakes, called <b>Holyrood Park</b>, which is great for long walks or runs.
            </p>,
            "image": "https://live.staticflickr.com/65535/50848744732_c37234ac5a_o.jpg"
        },
        {
            "name": "Greyfriars Cemetery",
            "description": <p>
                The Greyfriars Kirkyard is one of the <b>most haunted</b> cemeteries in the world.
                With several tours available to learn more about the legends of
                this place, it is an interesting area to be when hearing some scary stories. Next to the cemetery is also the
                tomb of the little dog <b>Bobby</b>, known for his famous history of guarding his owner's tomb.
            </p>,
            "image": "https://live.staticflickr.com/65535/50847932743_158c5cb81c_o.jpg"
        },
        {
            "name": "Elephant House",
            "description": <p>
               TThe famous Elephant House pub is located on the same street as the previous cemetery.
               This place became extremely well known because it was the place where J. K. Rowling wrote much of <b>Harry Potter</b>.
            </p>,
            "image": "https://live.staticflickr.com/65535/50848744707_50805701ae_o.jpg"
        },
        {
            "name": "Pub Crawl",
            "description": <p>
                Although it is not a cheap activity in this city, it is an extremely well-known and fun itinerary to be carried out.
                However take it easy, Scottish drinks are not weak!
            </p>,
            "image": "https://live.staticflickr.com/65535/50848744697_b84ab56c48_o.jpg"
        }
    ],

    "food": {
        "description": <p>
            Certainly, one important thing to do in Edinburgh is to taste the local cuisine!
            If you are searching for the best dishes, we give you an idea of where to go.
            </p>,

        "restaurants": [
            {
                "name": "MUMS Great Comfort Food",
                "description": "Excellent place to taste the famous Scottish delicacy, haggis. With a local and welcoming atmosphere and close to the center, it is a good option for this.",
                "price": 2,
                "image": "https://live.staticflickr.com/65535/50848656766_460a15b48a_o.jpg"
            },
            {
                "name": "Bertie's Restaurant & Bar",
                "description": "This is another place that serves a famous local and very traditional dish across the UK, Fish and Ships. It's also not a very expensive restaurant.",
                "price": 3,
                "image": "https://live.staticflickr.com/65535/50848656731_136e4db87b_o.jpg"
            },
            {
                "name": "Burgers and Beers Grillhouse",
                "description": "This place serves very tasty hamburgers for 10 pounds and the atmosphere is really cool.",
                "price": 2,
                "image": "https://live.staticflickr.com/65535/50847932643_3daa66685c_o.jpg"
            },
            {
                "name": "The Outsider Restaurant",
                "description": "It has several different dishes starting at 7 pounds, the portions are medium and very tasty. The staff is nice too.",
                "price": 2,
                "image": "https://live.staticflickr.com/65535/50847932618_8e68b360cc_o.jpg"
            },
            {
                "name": "Elephant House",
                "description": "With a long service, it deserves a visit just for being the place it is. The interior is fantastically decorated for fans.",
                "price": 3,
                "image": "https://live.staticflickr.com/65535/50847932603_2878668946_o.jpg"
            }
        ]
    },

    "stay": {
        "description": <p>
               Edinburgh has a lot of accommodation options, from hostels to five star hotels.
               However, it is not a cheap city, so be ready to spend a little more to find a good accommodation in a nice spot in the area.
            <br/><br/>
            Below we give you some suggestions of places to stay for every budget.
        </p>,

        "budget": [
            {
                "name": "CoDE Pod – The CoURT - Edinburgh",
                "description": "THE court in Edinburgh has a shared lounge and bar. This place is within walking distance of attractions like the Royal Mile, National Museum of Scotland, Camera Obscura and World of Illusions. Local points of interest such as Edinburgh Castle and the University of Edinburgh are within 600 meters and 800 meters, respectively. With relatively acceptable prices for a low budget it provides a good service.",
                "image": "https://live.staticflickr.com/65535/50848656656_ba2e4d8405_o.jpg",
                "link": "/"
            },
            {
                "name": "Kick Ass Greyfriars (18+)",
                "description": "Kick Ass Greyfriars (18+) is located in the center of Edinburgh and offers accommodation for adults only with a bar and a shared lounge. Some of the advantages of this place include a 24-hour reception, a shared kitchen and free Wi-Fi in all areas. The accommodation offers evening entertainment and a tour desk.",
                "image": "https://live.staticflickr.com/65535/50848656651_9989325e63_o.jpg",
                "link": "/"
            }
        ],
        "mid": [
            {
                "name": "Mercure Edinburgh City - Princes Street Hotel",
                "description": "Excellently located, next to Princes Street, opposite the Scott Monument and the National Gallery, this is a great choice for a larger budget. It offers a friendly atmosphere and views of the Edinburgh Castle. Free high-speed Wi-Fi is available throughout the property. ",
                "image": "https://live.staticflickr.com/65535/50848656636_481fa9780b_o.jpg",
                "link": "/"
            }
        ],
        "high": [
            {
                "name": "Novotel Edinburgh Centre",
                "description": "The Novotel Edinburgh Center is within 5-minute walk from the Grassmarket and features a hot tub and swimming pool. For high budgets it provides the luxury it promises. The property offers spacious and modern rooms with LCD TVs. Princes Street is a 10-minute walk away.",
                "image": "https://live.staticflickr.com/65535/50848744562_8f52c3607a_o.jpg",
                "link": "/"
            }
        ]
    }
}
